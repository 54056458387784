<template>
  <vue-apex-charts
    ref="chart"
    type="bar"
    height="370"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import { labelAlignment, labelMaxWidth, labelMinWidth } from '@/helpers/chartHelpers'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'DistributionOfTotalCostsChart',

  components: { VueApexCharts },

  mixins: [MixinCurrencies],

  props: {
    showTotals: {
      type: Boolean,
      default: false
    },
    hideTitle: {
      type: Boolean,
      default: false
    },
    chartAnimations: {
      type: Boolean,
      default: false
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        plotOptions: {
          bar: {
            horizontal: true
          }
        },
        stroke: {
          width: 2,
          colors: ['#fff']
        },
        title: {
          text: !this.hideTitle ? 'Distribution of Total Costs' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: '22px',
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        grid: {
          borderColor: 'transparent'
        },
        colors: ['#0291ff', '#0291ff'],
        dataLabels: {
          enabled: false
        },
        xaxis: {
          axisBorder: {
            show: false
          },
          lines: {
            show: false
          },
          axisTicks: {
            show: true,
            borderType: 'solid',
            color: 'transparent',
            offsetX: 0
          },
          categories: [this.$t('charts.distributionOfTotalCostsChart.directCosts'), this.$t('charts.distributionOfTotalCostsChart.payrollExpenses'),
            this.$t('charts.distributionOfTotalCostsChart.marketingExpenses'), this.$t('charts.distributionOfTotalCostsChart.otherOverheads'), this.$t('charts.distributionOfTotalCostsChart.startupCosts'), [this.$t('charts.distributionOfTotalCostsChart.interestExpenses'), this.$t('charts.distributionOfTotalCostsChart.incomeTaxes')]],
          labels: {
            formatter: this.formatChartLabels,
            minHeight: undefined,
            maxHeight: 40,
            style: {
              fontSize: '12px',
              fontFamily: 'outfit-regular',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          offsetY: 40,
          tickAmount: 10
        },
        yaxis: {
          axisBorder: {
            show: false,
            color: '#dadada',
            offsetX: 0,
            offsetY: 0
          },
          labels: {
            show: true,
            align: labelAlignment,
            minWidth: labelMinWidth,
            maxWidth: labelMaxWidth,
            style: {
              color: '#292f4d',
              fontSize: '14px',
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label'
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        tooltip: {
          marker: {
            show: false
          },
          y: {
            title: {
              formatter (seriesName) {
                return seriesName
              }
            },
            formatter: this.formatTooltip,
            show: true
          },
          x: {
            show: false
          },
          followCursor: false,
          theme: 'dark'
        },
        fill: {
          opacity: 1
        },
        legend: {
          show: false,
          position: 'top',
          horizontalAlign: 'left',
          offsetX: 20
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      let series = []

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.series &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.sorted
      ) {
        series = this.showTotals
          ? this.$store.state.idea.storyMode.forecast.totalCosts.calculation.series
          : this.$store.state.idea.storyMode.forecast.totalCosts.calculation.sorted
      }

      return series
    }
  },

  methods: {
    formatChartLabels (value) {
      const returnValue = (value / 1000) % 1 !== 0 ? (value / 1000).toFixed(3) : (value / 1000).toFixed(0)

      return this.currencySymbol + '' + returnValue + 'K'
    },

    formatTooltip (value) {
      if (value) {
        return this.currencySymbol + '' + this.getFormattedAmount(value)
      }
    }
  }
}
</script>
