<template>
  <vue-apex-charts
    ref="chart"
    type="bar"
    height="370"
    :options="chartOptions"
    :series="series"
  />
</template>

<script>
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import VueApexCharts from 'vue-apexcharts'
import responsiveChartOptions from '@/data/responsiveChartOptions'

export default {
  name: 'TimelineOfTotalCostsChart',

  components: {
    VueApexCharts
  },

  mixins: [MixinCharts, MixinCalculations, MixinCurrencies],

  props: {
    hideTitle: {
      type: Boolean,
      default: false
    },
    period: {
      type: String,
      default: 'monthly'
    },
    chartAnimations: {
      type: Boolean,
      default: false
    },
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      chartOptions: {
        chart: {
          toolbar: {
            show: false
          },
          background: '#fff',
          stacked: true,
          fontFamily: 'outfit-light,arial',
          animations: {
            enabled: this.chartAnimations,
            easing: 'easeinout',
            speed: 800,
            animateGradually: {
              enabled: true,
              delay: 150
            },
            dynamicAnimation: {
              enabled: true,
              speed: 350
            }
          }
        },
        title: {
          text: !this.hideTitle ? 'Timeline of Total Costs' : '',
          margin: 20,
          offsetX: 20,
          offsetY: 30,
          style: {
            fontSize: '22px',
            color: '#263238',
            fontFamily: 'outfit-light,arial'
          }
        },
        grid: {
          borderColor: 'transparent'
        },
        plotOptions: {
          bar: {
            horizontal: false,
            columnWidth: '55%'
          }
        },
        dataLabels: {
          enabled: false
        },
        stroke: {
          show: true,
          width: 1,
          colors: ['transparent']
        },
        xaxis: {
          categories: this.getCategories(),
          labels: {
            show: true,
            style: {
              color: '#afafb1',
              fontSize: '12px',
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-xaxis-label'
            }
          },
          axisTicks: {
            show: false
          },
          crosshairs: {
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0.4
            }
          }
        },
        yaxis: {
          tickAmount: 5,
          labels: {
            formatter: this.formatChartYLabels,
            show: true,
            align: 'right',
            // minWidth: 70,
            // maxWidth: 120,
            style: {
              color: '#afafb1',
              fontSize: '12px',
              fontFamily: 'outfit-light,arial',
              cssClass: 'apexcharts-yaxis-label',
              opacity: 0.7
            },
            offsetX: 0,
            offsetY: 0,
            rotate: 0
          },
          crosshairs: {
            show: false,
            width: 0,
            position: 'back',
            opacity: 0,
            dropShadow: {
              enabled: false,
              top: 0,
              left: 0,
              blur: 1,
              opacity: 0
            }
          }
        },
        fill: {
          opacity: 1
        },
        tooltip: {
          marker: {
            show: false
          },
          y: {
            title: {
              formatter (seriesName) {
                return seriesName
              }
            },
            formatter: this.formatTooltip
          },
          x: {
            show: false
          },
          theme: 'dark'
        },
        responsive: responsiveChartOptions
      }
    }
  },

  computed: {
    series () {
      const series = [{
        name: '',
        data: []
      }]

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.totalExpenses &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.totalExpenses.data
      ) {
        series[0].data = this.createSeriesDataFromCalculation(
          this.$store.state.idea.storyMode.forecast.totalCosts.calculation.totalExpenses.data
        )
      }

      return series
    }
  },

  watch: {
    period () {
      this.$set(this.chartOptions.xaxis, 'categories', this.getCategories())
      this.$refs.chart.refresh()
    }
  }
}
</script>
