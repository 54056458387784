import { render, staticRenderFns } from "./SummaryCard.vue?vue&type=template&id=1f85f172&scoped=true"
import script from "./SummaryCard.vue?vue&type=script&lang=js"
export * from "./SummaryCard.vue?vue&type=script&lang=js"
import style0 from "./SummaryCard.vue?vue&type=style&index=0&id=1f85f172&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "1f85f172",
  null
  
)

export default component.exports