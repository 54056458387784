<template>

  <!-- Total Cost Table -->
  <div class="switcher-inner-wrapper bg-white">
    <el-row :gutter="20">
      <el-col>

        <!-- Title & Period Switcher -->
        <div class="d-flex flex-column flex-md-row align-items-center align-items-md-start justify-content-between title-and-switcher-container">

          <!-- Title -->
          <h3 class="m-0 mb-3 mb-md-0 text-center text-md-left">
            {{ $t('charts.totalCostsSummary') }}
          </h3>
          <!-- /Title -->

          <div class="period-switcher">
            <table-period-switcher @change="period = $event" />
          </div>

        </div>
        <!-- /Title & Period Switcher -->

        <!-- Table -->
        <div id="monthly-table">
          <el-table
            ref="table"
            :data="tableData"
            row-key="id"
            :border="false"
            :stripe="false"
            size="small"
          >

            <!-- Fixed Column -->
            <el-table-column
              class-name="main-cells-1"
              label-class-name=""
              :fixed="!$breakpoint.xs"
              prop="name"
              :label="$t('charts.totalCosts')"
              :width="period === 'yearly' ? 550 : 200"
            />
            <!-- /Fixed Column -->

            <!-- Period Columns -->
            <el-table-column
              v-for="column in columns"
              :key="column.id"
              class-name="main-cells"
              label-class-name="table-labels"
              align="right"
              :label="column.label"
              :min-width="columnWidth"
            >
              <template slot-scope="scope">
                <span class="currency">{{ scope.row.values[column.name] ? currencySymbol : '' }}</span>
                <span>{{ scope.row.values[column.name] ? getRoundedFormattedAmount(scope.row.values[column.name]) : '' }}</span>
              </template>
            </el-table-column>
            <!-- /Period Columns -->

          </el-table>
        </div>
        <!-- /Table -->

      </el-col>
    </el-row>
  </div>
  <!-- /Total Cost Table -->

</template>

<script>
import MixinCurrencies from '@/mixins/currencies'
import MixinTable from '@/mixins/table'
import TablePeriodSwitcher from '@/components/Charts/Table/TablePeriodSwitcher'

export default {
  name: 'TotalCostsTable',

  components: {
    TablePeriodSwitcher
  },

  mixins: [
    MixinCurrencies,
    MixinTable
  ],

  props: {
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      period: 'monthly'
    }
  },

  computed: {
    tableData () {
      let tableData = []

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.tableData &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.tableData[this.period]
      ) {
        tableData = this.$store.state.idea.storyMode.forecast.totalCosts.calculation.tableData[this.period]
      }

      return tableData
    },

    columns () {
      let columns = []

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.columns[this.period]
      ) {
        columns = this.$store.state.idea.storyMode.forecast.totalCosts.calculation.columns[this.period]
      }

      return columns
    },

    columnWidth () {
      return '118'
    }
  },

  mounted () {
    this.switchTablePeriod('monthly')
  },

  methods: {
    switchTablePeriod (period) {
      this.$set(this, 'period', period)
    }
  }
}
</script>
<style scoped>
.period-switcher {
  max-width: 150px;
  min-width: 150px;
}

.title-and-switcher-container {
  padding: 0 30px;
}
</style>
