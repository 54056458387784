<template>

  <!-- Overview -->
  <div id="story-mode-overview">

    <!-- Container -->
    <div class="container-1200">

      <!-- Loader -->
      <div v-if="loading === true">
        <loader />
      </div>
      <!-- /Loader -->

      <div v-if="notFound === true" class="content not-found">
        <h1>{{ $t('pages.financialPlan.navigation.overview') }}</h1>
        <p class="text-lead">
          {{ $t('notFound') }}
        </p>
      </div>

      <!-- Content -->
      <div v-if="loading === false && notFound === false" class="content">

        <!-- Page Header -->
        <header class="page-header">

          <!-- Dashboard Header -->
          <div id="dashboard-header">
            <div class="header-wrapper">

              <!-- Title -->
              <h1>{{ $t('pages.financialPlan.navigation.overview') }}</h1>
              <!-- /Title -->

              <!-- Header Right -->
              <div class="header-right">

                <!-- Action Buttons -->
                <div class="action-buttons">
                  <ib-tooltip
                    popper-class="small center"
                    :content="$t('export')"
                  >
                    <!-- Download PDF -->
                    <el-dropdown trigger="click" @command="generateDocument">
                      <a class="el-dropdown-link print" @click.prevent>
                        <i class="ib-icon-download h3 text-silver hover-scale" />
                      </a>
                      <el-dropdown-menu slot="dropdown" class="export-dropdown-menu">
                        <el-dropdown-item
                          class="d-flex"
                          icon="ib-icon-export-pdf"
                          command="pdf"
                        >
                          {{ $t('exportToPdf') }}
                        </el-dropdown-item>
                        <el-dropdown-item
                          class="d-flex"
                          icon="ib-icon-export-word"
                          command="doc"
                        >
                          {{ $t('exportToWord') }}
                        </el-dropdown-item>
                        <el-dropdown-item
                          class="d-flex"
                          icon="ib-icon-export-excel"
                          command="excel"
                        >
                          {{ $t('exportToExcel') }}
                        </el-dropdown-item>
                      </el-dropdown-menu>
                    </el-dropdown>
                    <!-- /Download PDF -->
                  </ib-tooltip>

                </div>
                <!-- Action Buttons -->

              </div>
              <!-- Header Right -->

            </div>
          </div>
          <!-- /Dashboard Header -->

          <!-- Divider -->
          <hr>
          <!-- /Divider -->

        </header>
        <!-- /Page Header -->

        <!-- Height Holder For Sticky Header -->
        <div class="height-holder d-none d-md-block" />
        <!-- /Height Holder For Sticky Header -->

        <!-- Summary -->
        <overview-summary v-if="loading === false" id="summary" :shared-idea="sharedIdea" class="bg-white p-3" />
        <!-- /Summary -->

        <!-- Elements -->
        <draggable
          v-model="list"
          class="list-group"
          tag="div"
          animation="500"
          v-bind="dragOptions"
        >
          <transition-group type="transition" tag="div">
            <div
              v-for="element in list"
              :key="element.id"
              class="table-view filled"
            >

              <!-- Profit and Cash Flow -->
              <div
                v-if="element.type === types.PROFIT_AND_CASH_FLOW && element.isSelected && !isProfitAndCashFlowEmpty"
                id="profitAndCashFlow"
              >

                <!-- Title Profit & Cash Flow -->
                <h1> {{ $t('pages.financialPlan.navigation.profitAndCashFlow') }}</h1>
                <!-- /Title -->

                <!-- Charts And Table Filled & Buttons -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.PROFIT_AND_CASH_FLOW]">

                    <!-- Income / Expenses -->
                    <el-tab-pane :label="$t('incomeExpenses')" name="first">
                      <div v-if="active[types.PROFIT_AND_CASH_FLOW] === 'first'">
                        <profit-and-loss-projection :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Income / Expenses -->

                    <!-- Cash Flow -->
                    <el-tab-pane :label="$t('pages.financialPlan.cashFlow')" name="second">
                      <div v-if="active[types.PROFIT_AND_CASH_FLOW] === 'second'">
                        <cash-flow-projection class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Cash Flow -->

                    <!-- Summary -->
                    <el-tab-pane :label="$t('pages.financialPlan.summary')" name="third">
                      <div v-if="active[types.PROFIT_AND_CASH_FLOW] === 'third'" class="switcher-inner-wrapper">
                        <profit-and-loss-table :shared-idea="sharedIdea" class="pt-4" />
                        <cash-flow-table :shared-idea="sharedIdea" />
                      </div>
                    </el-tab-pane>
                    <!-- /Summary -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled & Buttons -->

              </div>
              <!-- /Profit and Cash Flow -->

              <!-- Revenue Streams -->
              <div
                v-if="element.type === types.REVENUE_STREAMS && element.isSelected && !isRevenueStreamsEmpty"
                id="revenue"
              >

                <!-- Title Revenue Streams -->
                <h1>{{ $t('pages.businessGuide.revenueStreams.title') }}</h1>
                <!-- /Title Revenue Streams -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.REVENUE_STREAMS]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.REVENUE_STREAMS] === 'first'">
                        <revenue-distribution class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.REVENUE_STREAMS] === 'second'">
                        <revenue-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.REVENUE_STREAMS] === 'third'">
                        <revenue-streams-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Revenue Streams -->

              <!-- Total Costs -->
              <div
                v-if="element.type === types.TOTAL_COSTS && element.isSelected && !isCostsEmpty"
                id="totalCosts"
              >

                <!-- Title Total Costs -->
                <h1>{{ $t('charts.totalCosts') }}</h1>
                <!-- /Title Total Costs -->

                <!-- Charts And Table Placeholders -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.TOTAL_COSTS]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.TOTAL_COSTS] === 'first'">
                        <distribution-of-total-costs class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.TOTAL_COSTS] === 'second'">
                        <timeline-of-total-costs :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.TOTAL_COSTS] === 'third'">
                        <total-costs-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Total Costs -->

              <!-- Management Team -->
              <div
                v-if="element.type === types.MANAGEMENT_TEAM && element.isSelected && !isManagementTeamEmpty && 1 === 2"
                id="managementTeam"
                class="management-team filled"
              >

                <!-- Title Management Team -->
                <h1>Management Team</h1>
                <!-- /Title Management Team -->

                <!-- Grid Wrapper -->
                <div ref="content" class="grid-wrapper active">
                  <el-row>
                    <el-col>
                      <el-row :gutter="20">

                        <!--Management Team Members -->
                        <div>
                          <el-col
                            v-for="member in managementTeamToShowGrid"
                            :key="member.id"
                            :md="5"
                            class="width-lg-20"
                          >
                            <div class="member-card temporary-class">

                              <!-- Card Top -->
                              <div class="card-top">

                                <!-- Member Image -->
                                <div
                                  class="avatar-holder" :class="{'default': !member.image }"
                                  :style="cardImage(member.image ? member.image.path + member.image.filename : '')"
                                />
                                <!-- /Member Image -->

                              </div>
                              <!-- /Card Top -->

                              <!-- Card Bottom -->
                              <div class="card-bottom">

                                <!-- Member Name -->
                                <h3>{{ member.name }}</h3>
                                <!-- /Member Name -->

                                <!-- Member Position -->
                                <p class="position">
                                  {{ member.position }}
                                </p>
                                <!-- /Member Position -->

                                <!-- Description -->
                                <p>{{ member.description }}</p>
                                <!-- /Description -->

                              </div>
                              <!-- /Card Bottom -->

                            </div>
                          </el-col>
                        </div>
                        <!--/Management Team Members -->

                      </el-row>
                    </el-col>

                    <!-- Show All -->
                    <el-col v-if="sharedIdea.storyMode.setUp.managementTeam.members.length > 4">
                      <div class="show-more" @click="showAllManagementTeamGrid = !showAllManagementTeamGrid">
                        <p>{{ showAllManagementTeamGrid ? 'Show Less' : 'Show All' }}</p>
                      </div>
                    </el-col>
                    <!-- /Show All -->

                  </el-row>
                </div>
                <!-- /Grid Wrapper -->

              </div>
              <!-- /Management Team -->

              <!-- Startup Costs -->
              <div
                v-if="element.type === types.STARTUP_COSTS && element.isSelected && !isStartupCostsEmpty"
                id="startupCosts"
              >

                <!-- Title Startup Costs -->
                <h1>{{ $t('tables.startupCosts.header') }}</h1>
                <!-- /Title Startup Costs -->

                <!-- Charts And Table Placeholders -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.STARTUP_COSTS]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.STARTUP_COSTS] === 'first'">
                        <distribution-of-startup-costs class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.STARTUP_COSTS] === 'second'">
                        <startup-costs-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.STARTUP_COSTS] === 'third'" class="switcher-inner-wrapper">
                        <startup-costs-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Startup Costs -->

              <!-- Financing -->
              <div
                v-if="element.type === types.FINANCING && element.isSelected && !isFinancesEmpty"
                id="financing"
              >

                <!-- Title Finances -->
                <h1>{{ $t('tables.financing.header') }}</h1>
                <!-- /Title Finances -->

                <!-- Charts And Table Placeholders -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.FINANCING]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.FINANCING] === 'first'">
                        <financing-sources class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.FINANCING] === 'second'">
                        <cash-flow-from-financing-activities :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.FINANCING] === 'third'" class="switcher-inner-wrapper">
                        <financing-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Financing -->

              <!-- Employee Expenses -->
              <div
                v-if="element.type === types.PAYROLL_EXPENSES && element.isSelected && !isStaffAndPayrollEmpty"
                id="payroll"
              >

                <!-- Title Staff & Payroll -->
                <h1>{{ $tc('payrollExpense', 2) }}</h1>
                <!-- /Title Staff & Payroll -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.PAYROLL_EXPENSES]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.PAYROLL_EXPENSES] === 'first'">
                        <distribution-of-payroll-expenses class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.PAYROLL_EXPENSES] === 'second'">
                        <payroll-expenses-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.PAYROLL_EXPENSES] === 'third'" class="switcher-inner-wrapper">
                        <staff-and-payroll-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Employee Expenses -->

              <!-- Direct Costs -->
              <div
                v-if="element.type === types.DIRECT_COSTS && element.isSelected && !isDirectCostsEmpty"
                id="directCosts"
              >

                <!-- Title Direct Costs -->
                <h1>{{ $t('tables.directCosts.header') }}</h1>
                <!-- /Title Direct Costs -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.DIRECT_COSTS]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.DIRECT_COSTS] === 'first'">
                        <distribution-of-direct-costs class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.DIRECT_COSTS] === 'second'">
                        <direct-costs-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.DIRECT_COSTS] === 'third'" class="switcher-inner-wrapper">
                        <direct-costs-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Direct Costs -->

              <!-- Marketing Budget -->
              <div
                v-if="element.type === types.MARKETING_BUDGET && element.isSelected && !isMarketingElementsEmpty"
                id="marketingBudget"
              >

                <!-- Title Marketing Element -->
                <h1>Marketing Element</h1>
                <!-- /Title Marketing Element -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.MARKETING_BUDGET]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.MARKETING_BUDGET] === 'first'">
                        <distribution-of-marketing-budget class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Breakdown -->

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.MARKETING_BUDGET] === 'second'">
                        <marketing-budget-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.MARKETING_BUDGET] === 'third'" class="switcher-inner-wrapper">
                        <marketing-budget-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Marketing Budget -->

              <!-- Other Overheads -->
              <div
                v-if="element.type === types.OTHER_OVERHEAD && element.isSelected && !isOtherOverheadEmpty"
                id="otherOverheads"
              >

                <!-- Title Other Overheads -->
                <h1>{{ $t('pages.businessGuide.otherOverheads.otherOverheads') }}</h1>
                <!-- /Title Other Overheads -->

                <!-- Charts And Table Filled -->
                <div class="table-view filled">
                  <el-tabs v-model="active[types.OTHER_OVERHEAD]">

                    <!-- Breakdown -->
                    <el-tab-pane :label="$t('breakdown')" name="first">
                      <div v-if="active[types.OTHER_OVERHEAD] === 'first'">
                        <distribution-of-other-overheads class="pt-4" />
                      </div>
                    </el-tab-pane>

                    <!-- Timeline -->
                    <el-tab-pane :label="$t('timeline')" name="second">
                      <div v-if="active[types.OTHER_OVERHEAD] === 'second'">
                        <other-overheads-over-time :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Timeline -->

                    <!-- Table -->
                    <el-tab-pane :label="$t('table')" name="third">
                      <div v-if="active[types.OTHER_OVERHEAD] === 'third'" class="switcher-inner-wrapper">
                        <other-overhead-table :shared-idea="sharedIdea" class="pt-4" />
                      </div>
                    </el-tab-pane>
                    <!-- /Table -->

                  </el-tabs>
                </div>
                <!-- /Charts And Table Filled -->

              </div>
              <!-- /Other Overheads -->

            </div>
          </transition-group>
        </draggable>
        <!-- /Elements -->

      </div>
      <!-- /Content -->

    </div>
    <!-- /Container -->

    <!-- Dialog Export/Generate -->
    <dialog-generate
      :active="active"
      :format="generateFormat"
      :dialog-visible="generateDialog"
      :shared-idea="sharedIdea"
      @close="generateDialog = false"
    />
    <!-- /Dialog Export/Generate -->

  </div>
  <!-- /Overview -->

</template>

<script>
import CashFlowFromFinancingActivities
  from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/CashFlowFromFinancingActivities'
import CashFlowProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/CashFlowProjection'
import CashFlowTable from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Tables/CashFlowTable'
import DialogGenerate from '@/views/Home/StoryMode/Components/Dialogs/DialogGenerate'
import DirectCostsOverTime
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DirectCostsOverTime'
import DirectCostsTable from '@/views/Home/StoryMode/Forecast/DirectCosts/Tables/DirectCostsTable'
import DistributionOfDirectCosts
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/DirectCost/DistributionOfDirectCosts'
import DistributionOfMarketingBudget
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/DistributionOfMarketingBudget'
import DistributionOfOtherOverheads
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/DistributionOfOtherOverheads'
import DistributionOfPayrollExpenses
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/DistributionOfPayrollExpenses'
import DistributionOfStartupCosts
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/DistributionOfStartupCosts'
import DistributionOfTotalCosts
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/TotalCost/DistributionOfTotalCosts'
import Draggable from 'vuedraggable'
import FinancingSources from '@/views/Home/StoryMode/FinancialPlan/Components/Financing/FinancingSources'
import FinancingTable from '@/views/Home/StoryMode/SetUp/Financing/Tables/FinancingTable'
import Loader from '@/components/Loader'
import MarketingBudgetOverTime
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/MarketingCost/MarketingBudgetOverTime'
import MarketingBudgetTable from '@/views/Home/StoryMode/Forecast/MarketingBudget/Tables/MarketingBudgetTable'
import MixinApiCalculations from '@/mixins/apiCalculations'
import MixinCurrencies from '@/mixins/currencies'
import MixinGenerateDocument from '@/mixins/generateDocument'
import OtherOverheadTable from '@/views/Home/StoryMode/Forecast/OtherOverheads/Tables/OtherOverheadTable'
import OtherOverheadsOverTime
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/OtherOverheads/OtherOverheadsOverTime'
import OverviewSummary from '@/views/Home/StoryMode/FinancialPlan/Summary/Summary'
import PayrollExpensesOverTime
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/PayrollExpense/PayrollExpensesOverTime'
import ProfitAndLossProjection from '@/views/Home/StoryMode/FinancialPlan/Components/Overview/ProfitAndLossProjection'
import ProfitAndLossTable from '@/views/Home/StoryMode/Forecast/ProfitAndCashFlow/Tables/ProfitAndLossTable'
import RevenueDistribution from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueDistribution'
import RevenueOverTime from '@/views/Home/StoryMode/FinancialPlan/Components/Revenue/RevenueOverTime'
import RevenueStreamsTable from '@/views/Home/StoryMode/Forecast/RevenueStreams/Tables/RevenueStreamsTable'
import StaffAndPayrollTable from '@/views/Home/StoryMode/SetUp/StaffAndPayroll/Tables/StaffAndPayrollTable'
import StartupCostsOverTime
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/StartupCost/StartupCostsOverTime'
import StartupCostsTable from '@/views/Home/StoryMode/SetUp/StartupCosts/Tables/StartupCostsTable'
import TimelineOfTotalCosts
  from '@/views/Home/StoryMode/FinancialPlan/Components/Expenses/TotalCost/TimelineOfTotalCosts'
import TotalCostsTable from '@/views/Home/StoryMode/FinancialPlan/Tables/TotalCostsTable'
import { types } from '@/constants/OverviewElement'

export default {
  name: 'FinancialPlanShared',

  components: {
    OtherOverheadsOverTime,
    DistributionOfOtherOverheads,
    DistributionOfTotalCosts,
    MarketingBudgetOverTime,
    DistributionOfMarketingBudget,
    DirectCostsOverTime,
    DistributionOfDirectCosts,
    PayrollExpensesOverTime,
    DistributionOfPayrollExpenses,
    StartupCostsOverTime,
    DistributionOfStartupCosts,
    CashFlowFromFinancingActivities,
    FinancingSources,
    TimelineOfTotalCosts,
    RevenueOverTime,
    RevenueDistribution,
    CashFlowProjection,
    CashFlowTable,
    DirectCostsTable,
    Draggable,
    FinancingTable,
    ProfitAndLossTable,
    Loader,
    MarketingBudgetTable,
    OtherOverheadTable,
    OverviewSummary,
    ProfitAndLossProjection,
    RevenueStreamsTable,
    StaffAndPayrollTable,
    StartupCostsTable,
    TotalCostsTable,
    DialogGenerate
  },

  mixins: [
    MixinCurrencies,
    MixinGenerateDocument,
    MixinApiCalculations
  ],

  data () {
    return {
      generateDialog: false,
      generateFormat: '',
      sharedIdea: {},
      loading: true,
      notFound: false,
      list: [],
      types,
      active: {
        [types.REVENUE_STREAMS]: 'first',
        [types.TOTAL_COSTS]: 'first',
        [types.PAYROLL_EXPENSES]: 'first',
        [types.MARKETING_BUDGET]: 'first',
        [types.PROFIT_AND_CASH_FLOW]: 'first',
        [types.STARTUP_COSTS]: 'first',
        [types.FINANCING]: 'first',
        [types.DIRECT_COSTS]: 'first',
        [types.OTHER_OVERHEAD]: 'first'
      },
      showAllManagementTeamGrid: false,
      tooltipsVisible: true,
      tooltipsBottomVisible: true,
      output: null,
      pdfLoading: false
    }
  },

  computed: {
    dragOptions () {
      return {
        disabled: true
      }
    },

    isSummaryEmpty () {
      return false
    },

    isRevenueStreamsEmpty () {
      return this.sharedIdea.storyMode.forecast.revenueStreams.revenueStreams.length === 0
    },

    isCostsEmpty () {
      return this.sharedIdea.storyMode.forecast.directCosts.directCosts.length === 0 ||
        this.sharedIdea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length === 0 ||
        this.sharedIdea.storyMode.forecast.marketingBudget.marketingElements.length === 0 ||
        this.sharedIdea.storyMode.forecast.otherOverheads.otherOverheads.length === 0 ||
        this.sharedIdea.storyMode.setUp.startupCosts.startupCosts.length === 0 ||
        this.sharedIdea.storyMode.setUp.financing.finances.length === 0 ||
        this.sharedIdea.storyMode.forecast.revenueStreams.revenueStreams.length === 0
    },

    isStaffAndPayrollEmpty () {
      return this.sharedIdea.storyMode.setUp.staffAndPayrolls.staffAndPayrolls.length === 0
    },

    isMarketingElementsEmpty () {
      return this.sharedIdea.storyMode.forecast.marketingBudget.marketingElements.length === 0
    },

    isProfitAndCashFlowEmpty () {
      return false
    },

    isManagementTeamEmpty () {
      return this.sharedIdea.storyMode.setUp.managementTeam.members.length === 0
    },

    isStartupCostsEmpty () {
      return this.sharedIdea.storyMode.setUp.startupCosts.startupCosts.length === 0
    },

    isFinancesEmpty () {
      return this.sharedIdea.storyMode.setUp.financing.finances.length === 0
    },

    isDirectCostsEmpty () {
      return this.sharedIdea.storyMode.forecast.directCosts.directCosts.length === 0
    },

    isOtherOverheadEmpty () {
      return this.sharedIdea.storyMode.forecast.otherOverheads.otherOverheads.length === 0
    },

    managementTeamToShowGrid () {
      return this.showAllManagementTeamGrid === true
        ? this.sharedIdea.storyMode.setUp.managementTeam.members
        : this.sharedIdea.storyMode.setUp.managementTeam.members.slice(0, 4)
    }
  },

  mounted () {
    this.getSharedOverviewElements()
  },

  methods: {
    generateDocument (format) {
      this.generateFormat = format
      this.generateDialog = true
    },

    getSharedOverviewElements () {
      this.$http.get(
        'story-mode/overview/shared',
        {
          params: { token: this.$route.params.token }
        }
      ).then(response => {
        this.$set(this, 'list', response.data.payload.overviewElements)
        this.setSharedIdea(response.data.payload.idea)
        this.setStartupCosts(response.data.payload.calculation.startupCosts)
        this.setStaffAndPayroll(response.data.payload.calculation.staffAndPayrolls)
        this.setFinancing(response.data.payload.calculation.financing)
        this.setRevenueStreams(response.data.payload.calculation.revenueStreams)
        this.setDirectCosts(response.data.payload.calculation.directCosts)
        this.setMarketingBudget(response.data.payload.calculation.marketingBudget)
        this.setOtherOverheads(response.data.payload.calculation.otherOverheads)
        this.setProfitAndCashFlow(response.data.payload.calculation.profitAndCashFlow)
        this.setTotalCosts(response.data.payload.calculation.totalCosts)
      }).catch(() => {
        this.notFound = true
      }).finally(() => {
        this.loading = false
      })
    },

    setSharedIdea (idea) {
      this.sharedIdea = {
        id: idea.id,
        name: idea.name,
        ideaSettings: idea.ideaSettings,
        catalogueCategory: idea.catalogueCategory,
        catalogueIdea: idea.catalogueIdea,
        storyMode: {
          setUp: {
            startupCosts: {
              startupCosts: idea.startupCosts
            },
            financing: {
              finances: idea.financing
            },
            managementTeam: {
              members: idea.members
            },
            staffAndPayrolls: {
              staffAndPayrolls: idea.staffAndPayroll,
              laborBurden: idea.laborBurden
            }
          },
          forecast: {
            revenueStreams: {
              revenueStreams: idea.revenueStreams,
              salesTaxRate: idea.salesTaxRate
            },
            directCosts: {
              directCosts: idea.directCosts
            },
            marketingBudget: {
              marketingElements: idea.marketingElements
            },
            otherOverheads: {
              otherOverheads: idea.otherOverheads
            },
            profitAndCashFlow: {
              dividends: idea.dividends,
              cashCycle: idea.cashCycle,
              incomeTax: idea.incomeTax
            }
          }
        }
      }
    },

    cardImage (img) {
      if (img) {
        return 'background-image: url(' + img + ')'
      } else {
        return ''
      }
    }
  }
}
</script>

<style scoped lang="scss">
  #story-mode-overview .content.not-found h1,
  #story-mode-overview .content.not-found p {
    margin: 0;
    padding: 0;
  }

  #story-mode-overview {
    margin-left: 0;
    background-color: $color-text-lightest-blue;
    padding-bottom: 100px;
  }

  .temporary-class {
    padding-top: 45px;
  }

  .page-header {
    width: 100%;
    left: 0;
    position: static;
  }
</style>
