<template>
  <ib-card class="pa-4" :animation="false">
    <div class="d-flex summary-card">
      <img class="summary-image" :src="image" alt="">
      <div>
        <p class="ma-0 price">
          {{ price }}
        </p>
        <p class="ma-0 title">
          {{ title }}
        </p>
      </div>
    </div>
  </ib-card>
</template>

<script>
export default {
  name: 'SummaryCard',
  props: {
    image: {
      type: String,
      required: true
    },
    price: {
      type: String,
      required: true
    },
    title: {
      type: String,
      required: true
    }
  }
}
</script>

<style scoped lang="scss">
.price {
  line-height: 32px;
  font-size: 28px;
  font-family: $outfit-semi-bold;
}

.title {
  font-size: 1.1rem !important;
  opacity: 0.5;
  font-family: 'outfit-light', sans-serif !Important;
}

.summary-image {
  margin-right: 25px;
  padding-left: 16px;
  width: 70px;
}
</style>
