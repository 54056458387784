<template>
  <v-app>
    <!-- Summary -->
    <div class="d-flex justify-content-between mb-4">
      <div>
        <h2 class="ma-0">
          {{ maxChar(getIdea().name, 30) }}
        </h2>
        <h5 class="ma-0">
          {{ getIdea().catalogueIdea ? getIdea().catalogueIdea.name : 'Other ' + getIdea().catalogueCategory.name }}
        </h5>
      </div>
      <div class="switcher-container">
        <yearly-select
          v-model="selectedYear"
          :items="yearsOptions"
        />
      </div>
    </div>
    <v-row justify="center">
      <v-col :cols="12" :xl="4">
        <summary-card
          :image="require('@/assets/img/icons/overview-icons/total-revenue-icon.svg')"
          :title="$t('pages.businessGuide.revenueStreams.totalRevenue')"
          :price="`${currencySymbol}${getRoundedFormattedAmount(yearTotalIncome)}`"
        />
      </v-col>
      <v-col :cols="12" :xl="4">
        <summary-card
          :image="require('@/assets/img/icons/overview-icons/total-costs-icon.svg')"
          :title="$t('charts.totalCosts')"
          :price="`${currencySymbol}${getRoundedFormattedAmount(yearTotalExpenses)}`"
        />
      </v-col>
      <v-col :cols="12" :xl="4">
        <summary-card
          :image="require('@/assets/img/icons/overview-icons/net-profit-icon.svg')"
          :title="$t('charts.netProfit')"
          :price="`${currencySymbol}${getRoundedFormattedAmount(yearNetProfit)}`"
        />
      </v-col>
    </v-row>
  </v-app>
</template>

<script>
import MixinCalculations from '@/mixins/calculations'
import MixinCharts from '@/mixins/charts'
import MixinCurrencies from '@/mixins/currencies'
import SummaryCard from '@/views/Home/StoryMode/FinancialPlan/Summary/SummaryCard.vue'
import YearlySelect from '@/components/Charts/Components/YearlySelect.vue'

export default {
  name: 'Summary',

  components: { SummaryCard, YearlySelect },

  mixins: [
    MixinCalculations,
    MixinCharts,
    MixinCurrencies
  ],

  props: {
    sharedIdea: {
      type: Object,
      default: null
    }
  },

  data () {
    return {
      selectedYear: 1
    }
  },

  computed: {
    yearsOptions () {
      const years = []
      const yearsToShow = this.numberOfYearsToShow > 3 ? 5 : 3
      for (let year = 1; year <= yearsToShow; year++) {
        years.push(year)
      }

      return years.map(year => {
        return {
          text: `Y${year}`,
          value: year
        }
      })
    },

    yearTotalIncome () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalRevenue.yearTotalIncome[this.selectedYear]
      }

      return result
    },

    yearTotalExpenses () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.totalExpenses.yearTotalExpenses[this.selectedYear]
      }

      return result
    },

    yearNetProfit () {
      let result = 0

      if (
        this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit &&
          this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      ) {
        result = this.$store.state.idea.storyMode.forecast.profitAndCashFlow.calculation.netProfit.yearNetProfit[this.selectedYear]
      }

      return result
    }
  },

  methods: {
    maxChar (text, count) {
      return text.slice(0, count) + (text.length > count ? '...' : '')
    }
  }
}
</script>

<style scoped lang="scss">
h5 {
  opacity: .5;
}
</style>
