<template>
  <chart-component>
    <!-- Title -->
    <template #title>
      {{ $t('charts.distributionOfTotalCosts') }}
    </template>
    <!-- /Title -->
    <!-- Subtitle -->
    <template #subtitle>
      {{ $t('pages.businessGuide.monthlyAverage') }}
    </template>
    <!-- /Subtitle -->
    <!-- Breakdown Total Switcher -->
    <template #switcher>
      <breakdown-total-switcher
        class="switcher"
        @change="showTotals = $event"
      />
    </template>
    <!-- /Breakdown Total Switcher -->
    <!-- Chart -->
    <template #chart>
      <distribution-of-total-costs-chart
        hide-title
        :show-totals="showTotals"
      />
    </template>
    <!-- /Chart -->
    <!-- Right side -->
    <template #right-side>
      <chart-component-right-side>
        <total-amount
          class="mb-3"
          :amount="totalAmount"
          :text="$t('totalMonthlyAverage')"
        />
        <div>
          <p class="m-0 text-small mb-4">
            {{ $t('topThreeAverage') }}
          </p>
          <template v-for="(cost, index) in topCosts">
            <year-info
              :key="index"
              class="mb-3"
              :avatar-text="index + 1"
              :text="cost.name"
              circle
              :money="returnPositiveIntegerFromArray(cost.data)"
            />
          </template>
        </div>
      </chart-component-right-side>
    </template>
    <!-- /Right side -->
  </chart-component>
</template>

<script>
import BreakdownTotalSwitcher from '@/components/Charts/Breakdown/BreakdownTotalSwitcher'
import ChartComponent from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponent'
import ChartComponentRightSide from '@/views/Home/StoryMode/FinancialPlan/Components/ChartComponentRightSide'
import DistributionOfTotalCostsChart from '@/views/Home/StoryMode/FinancialPlan/Charts/DistributionOfTotalCostsChart'
import TotalAmount from '@/views/Home/StoryMode/FinancialPlan/Components/TotalAmount'
import YearInfo from '@/views/Home/StoryMode/FinancialPlan/Components/YearInfo'

export default {
  name: 'DistributionOfTotalCosts',

  components: {
    BreakdownTotalSwitcher,
    ChartComponent,
    ChartComponentRightSide,
    DistributionOfTotalCostsChart,
    TotalAmount,
    YearInfo
  },

  data () {
    return {
      showTotals: false
    }
  },

  computed: {
    totalAmount () {
      let totalCosts = 0

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.totalCosts
      ) {
        totalCosts = this.$store.state.idea.storyMode.forecast.totalCosts.calculation.totalCosts
      }

      return totalCosts
    },

    topCosts () {
      let sorted = []

      if (
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation &&
        this.$store.state.idea.storyMode.forecast.totalCosts.calculation.sorted
      ) {
        sorted = this.$store.state.idea.storyMode.forecast.totalCosts.calculation.sorted
      }

      return sorted.slice(0, 3)
    }
  },

  methods: {
    returnPositiveIntegerFromArray (array) {
      const filtered = array.filter(item => item > 0)

      return filtered.length !== 0 ? filtered[0] : 0
    }
  }
}
</script>
